import React from 'react';
import { graphql } from 'gatsby';
import {
  ColorDataTableDesign,
  DontDo,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Section,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Identity"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Identity" />

      <PageNavigation links={['Usage', 'Values']} />

      <Section title="Usage">
        <DontDo
          dontText="use identity colors as text."
          doText="use identity colors as accents."
          imgFilename="colors-identity"
        />
      </Section>
      <Section title="Values">
        <ColorDataTableDesign data={data} />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query identityColorsDesign($type: String = "/Identity/") {
    ...colorDataDesign
  }
`;
